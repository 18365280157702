import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { Catalog, Question } from './types'
import config from './config'

interface SEOProps {
  title: string
  description: string
  article: boolean
}

export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  article,
  children,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const { titleTemplate, siteUrl } = site.siteMetadata

  const seo = {
    title,
    description,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />

      {article && <meta property="og:type" content="article" />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      <html lang={'es'} />

      {children}
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`

export const getLDJSON = (name: 'home', business: string): string => {
  if (name === 'home') {
    return JSON.stringify(businessLDJSON(business))
  }

  throw new Error('Invalid LD-JSON: ' + name)
}

const businessLDJSON = (business: string) => [
  {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: config.main.name,
    url: config.main.url,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${config.main.url}/busqueda/?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  },
  {
    '@context': 'https://schema.org',
    '@type': business,
    address: {
      '@type': 'PostalAddress',
      ...config.main.address,
    },
    image: config.main.image(),
    name: config.main.name,
    openingHours: [
      {
        '@type': 'OpeningHoursSpecification',
        ...config.main.schedules.ld[0],
      },
    ],
    priceRange: config.main.priceRange.ld,
    telephone: config.contact.extendedPhoneNumbers[0],
    url: config.main.url,
    currenciesAccepted: config.main.currenciesAccepted.join(', '),
    paymentAccepted: config.main.paymentAccepted.join(', '),
  },
]

export const buildCatalogLDSJSON = (
  business: string,
  catalog: Catalog,
): string => {
  const json = buildCatalog(business, catalog)

  return JSON.stringify(json)
}

export const LDJSON: React.FC<{ data: any }> = ({ data, children }) => {
  return (
    <script
      type="application/ld+json"
      children={children || JSON.stringify(data)}
    />
  )
}

export const buildCatalog = (business: string, catalog: Catalog): object => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Service',
    serviceType: 'Weekly home cleaning',
    provider: {
      ...businessLDJSON(business),
    },
    areaServed: {
      '@type': 'City',
      name: config.main.address.addressLocality,
    },
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: catalog.name,
      itemListElement: catalog.categories.map(service => ({
        '@type': 'OfferCatalog',
        name: service.name,
        itemListElement: service.services.map(offer => ({
          '@type': 'AggregateOffer',
          lowPrice: offer.lowPrice,
          highPrice: offer.highPrice,
          priceCurrency: offer.priceCurrency || 'COP',
          itemOffered: {
            '@type': 'Service',
            name: offer.name,
            description: offer.description,
            // image: offer.image,
          },
        })),
      })),
    },
  }
}

export const buildFAQ = (questions: Question[]): object => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.map(question => ({
      '@type': 'Question',
      name: question.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: question.answer,
      },
    })),
  }
}
